.markdown-content {
    text-align: left;
    font-size: 13px;
}

.page-content-box {
    word-break: break-word;
    border: 1px solid #dadce0;
    text-align: left;
    max-width: 800px;
    border-radius: 8px;
    margin: 0 auto;
    padding: 10px 25px;
    max-height: inherit;
    overflow-x: hidden;
    overflow-y: auto;
}

.page-content-box .MuiList-padding,
.app-content-wrapper .MuiList-padding {
    padding-top: 0;
    padding-bottom: 0;
}

.md-dialog .page-content-box {
    box-shadow: none;
    border: none;
    padding: 0;
    margin: 0;
}

.md-dialog-close-button.MuiButtonBase-root {
    position: absolute;
    right: 0;
    top: 0;
}

.md-dialog .MuiDialogContent-root {
    padding: 0 8px 8px;
}

.md-dialog-title {
    display: table;
}

/*
  Device = Mobiles
*/
@media screen and (max-device-width: 767px) {
    .page-content-box {
        width: auto;
        margin: 10px 20px;
        padding: 5px 15px;
    }
}
