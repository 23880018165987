.datepicker {
    width: 140px;
}

div.datepicker-border-bottom {
    padding-bottom: 5px;
    border-bottom: 1px solid;
}

.datepicker div.error {
    color: red;
}

.datepicker div.pointer {
    cursor: pointer;
}

.datepicker .non-pointer {
    cursor: default;
}

.datepicker div.input-non-pointer input {
    cursor: default;
}

.datepicker div.button-non-pointer button {
    cursor: default;
}

.datepicker div.bold {
    font-weight: bold;
}

.datepicker div.datepicker-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.datepicker div.datepicker-column {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.datepicker div.datepicker-left {
    margin-right: auto;
}

.datepicker div.datepicker-right {
    margin-left: auto;
}

.datepicker div.datepicker-top {
    margin-bottom: auto;
}

.datepicker div.datepicker-bottom {
    margin-top: auto;
}

.datepicker div.MuiFormControl-root {
    width: 100%;
}

.datepicker fieldset {
    border: 0;
}

.datepicker input {
    padding: 0;
}

.datepicker input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.datepicker svg {
    color: #00a9fa;
}

.datepicker button {
    padding: 0;
}

.datepicker button:hover {
    background: none;
}
