.track-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.track-datepicker {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
    flex-direction: inherit;
    background: rgba(255, 255, 255, 1);
}

.track-content {
    background-color: #f0f3f5;
    height: calc(100% - 175px);
    display: flex;
    align-items: center;
    flex-direction: column;
}

.track-button-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.track-content-above-bottom {
    flex-grow: 1;
}

.track-content-bottom {
    margin-bottom: 20px;
}

.track-content-first-day-container {
    display: flex;
    flex-direction: row;
    min-width: 250px;
    justify-content: space-between;
}

.track-content-first-day-label {
    display: flex;
    align-items: center;
}

.track-content-first-day-switch {
    display: flex;
    align-items: center;
}

.track-content-first-day-switch .Mui-checked .MuiSwitch-thumb {
    color: rgba(39, 183, 255, 1);
}
.track-content-first-day-switch .Mui-checked + .MuiSwitch-track {
    background-color: rgba(39, 183, 255, 0.4);
}

.track-button-select {
    margin-top: 20px;
}

img.light-period {
    width: 11px;
}

img.meduim-period {
    width: 15px;
}

img.heavy-period {
    width: 20px;
}

img.spotting {
    width: 20px;
}
