.button-select-container {
    display: flex;
    flex-direction: column;
}

button.button-select-buton {
    margin-top: 10px;
}

button.white-bg:hover {
    color: rgba(39, 183, 255, 1);
    background-color: rgba(255, 255, 255, 1);
    border-color: rgba(39, 183, 255, 1);
}

.button-select-inner-button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.button-select-inner-button-right,
.button-select-inner-button-left {
    flex: 1;
}

.button-select-inner-button-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.button-select-container .box {
    width: fit-content;
}

.button-select-inner-button-right {
    display: flex;
    justify-content: end;
}

.button-select-inner-button-right .box {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    width: 30px;
}
