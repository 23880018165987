div.cycle-tracker-container {
    display: flex;
    min-width: 300px;
    min-height: 400px;
    width: 100%;
    height: 100%;
    max-width: 600px;
    max-height: 600px;
    justify-content: center;
    align-items: center;
    touch-action: none;
}

.cycle-tracker-marker {
    font-size: 20px;
}

@media only screen and (max-height: 820px) {
    div.cycle-tracker-container {
        max-height: 400px;
    }
}
