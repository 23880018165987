.fullscreen-popup-container {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 1);
    z-index: 5;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.fullscreen-popup-content {
    position: relative;
    height: calc(100vh - 50px);
}
