.login-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    max-width: 800px;
    justify-content: center;
    text-align: center;
    gap: 20px;
    align-items: center;
}

.blut-login {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    min-height: 300px;
}

.blut-login .blut-welcome-image img {
    height: 100%;
}

.blut-login .blut-welcome-image {
    margin-top: 5%;
    height: calc(100% - 360px);
    max-height: 300px;
}

.login-title {
    letter-spacing: 0.15px;
    margin: 8px 0 20px 0;
    font-weight: bold;
    line-height: 150%;
    align-self: stretch;
}

.forgot-password {
    margin-top: -15px;
    font-style: italic;
    width: fit-content;
    align-self: end;
}

.login-content .blut-btn.MuiButton-root {
    margin: 0;
    margin-top: 20px;
}
