/* COLORS */
.onboarding,
.cnt-create-options,
.upload-status-image,
.list-element {
    background: #ffffff;
}

.editor,
.upload-image .MuiSvgIcon-root,
.cnt-empty-image {
    background: #c4c4c4;
}

.cnt-add-menu .MuiPaper-root {
    background: transparent;
}

.notification-close-button .MuiIconButton-label,
.upload-image .MuiSvgIcon-root {
    color: #ffffff;
}

.notification-error .MuiSnackbarContent-root {
    background: #c54444;
}

.notification-success .MuiSnackbarContent-root {
    background: #6aa84f;
}

.notification-info .MuiSnackbarContent-root {
    background: #4473c5;
}

.select-item .MuiButtonBase-root,
.login-title {
    color: black;
}

.cnt-add-menu .MuiPaper-root {
    color: transparent;
}

.dark-orange,
.light-grey.dark-orange {
    background: #f2994a38;
}

.light-grey {
    background: #e0e0e0;
}

.view-title,
.upload-status-image,
.add-status,
.family-member-id {
    color: #828282;
}

.btn-add-information {
    border-block-color: rgba(0, 125, 255, 0.5);
}

.status-date {
    color: #00000089;
}

.signatures-list,
.profile-trusted {
    color: #0000008a;
}

.forgot-password {
    color: #4473c5;
}
/* END OF COLOR */

/* FONT SIZE */
.signed-container,
.emojis {
    font-size: 30px;
}

.h1 {
    font-size: 24px;
}

.login-title {
    font-size: 18px;
}

.body,
.body1,
.select-item .MuiButtonBase-root,
.font-size-16 {
    font-size: 16px;
}

.btn-add-information,
.forgot-password {
    font-size: 14px;
}

.view-title,
.status-date,
.family-member-id {
    font-size: 12px;
}

.body2 {
    font-size: 10px;
}
/*END OF FONT SIZE*/

/* FONT STYLE */
.h1,
.body,
.body1 {
    font-weight: normal;
}

.h1,
.body,
.body1 {
    line-height: 150%;
}

.edit-information {
    line-height: 143%;
}

.edit-information {
    text-align: center;
    letter-spacing: 0.15px;
}

.body1 {
    font-style: italic;
}

.h1,
.body,
.body2 {
    font-style: normal;
}
/* END OF FONT STYLE */

.editor {
    display: flex;
}

.editor {
    justify-content: center;
}

.editor {
    align-items: center;
}

.editor {
    flex-direction: column;
}

.onboarding,
.editor {
    height: 100vh;
}

.onboarding,
.editor {
    overflow: auto;
}

.onboarding {
    min-height: 500px;
}

.editor {
    min-height: 200px;
}

.circular-progress {
    position: absolute;
    top: 50%;
    left: 50%;
}

.view-title {
    text-transform: uppercase;
    margin: 10px 0 10px 5px;
}

#root {
    height: 100vh;
    position: relative;
}

.position-absolute {
    position: absolute;
}

button.big-rounded {
    width: 215px;
    height: 40px;
    border-radius: 100px;
    border: 2px solid;
}

button.white-bg {
    color: rgba(39, 183, 255, 1);
    background-color: rgba(255, 255, 255, 1);
    border-color: rgba(255, 255, 255, 1);
}

button.white-bg:hover {
    border-color: rgba(39, 183, 255, 1);
    background-color: rgba(255, 255, 255, 1);
}

button.blue-bg {
    color: rgba(255, 255, 255, 1);
    background-color: rgba(39, 183, 255, 1);
    border-color: rgba(255, 255, 255, 1);
}

button.blue-bg:hover {
    color: rgba(255, 255, 255, 1);
    background-color: rgba(39, 183, 255, 1);
    border-color: rgba(39, 183, 255, 1);
}
