.blut-registration {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.blut-welcome {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    min-height: 300px;
}

.blut-welcome .blut-welcome-image {
    margin-top: 5%;
    height: calc(95% - 325px);
}

.blut-welcome .blut-welcome-image img {
    height: 100%;
}

.blut-registration .registerPassword {
    margin-bottom: 70px;
}

.blut-register-image {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 5%;
}

.blut-page-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0 10px 0 10px;
    max-width: 800px;
}

.blut-registration .blut-page-content {
    height: calc(100% - 105px);
    min-height: 370px;
}

.blut-registration .blut-register-image img {
    height: 100%;
}

.blut-registration .blut-register-image {
    height: calc(100% - 365px);
    max-height: 320px;
}

.text-centered {
    text-align: center;
}

.content-centered {
    justify-content: center;
}

.blut-pdf-content {
    margin: 20px 10px 0 10px;
    max-width: 800px;
    overflow: auto;
}

/*
font styles
*/
.blut-information {
    letter-spacing: 0.15px;
    font-size: 14px;
    color: black;
    margin: 8px 0 8px 0;
}

.title {
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    align-self: stretch;
}

/*
/Buttons
 */
.blut-registration .blut-btn.MuiButton-root {
    margin-bottom: 70px;
}
.blut-btn.MuiButton-root {
    border: 1px solid;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.4px;
    margin-bottom: 20px;
}

.disabled.MuiButton-root {
    background-color: #c3c3c3;
    color: white;
}

.navigation-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    max-width: 800px;
    margin-top: 10px;
}

.nav-btn.MuiButton-root {
    color: #4473c5;
    border: 1px solid;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
}

.left {
    margin-left: 15px;
}

.right {
    margin-right: 15px;
}

.blut-link {
    color: rgba(39, 183, 255, 1);
    text-decoration: underline;
    cursor: pointer;
}

.MuiCheckbox-colorPrimary {
    color: rgba(39, 183, 255, 1) !important;
}

.MuiInput-underline:after {
    border-bottom: 2px solid rgba(39, 183, 255, 1) !important;
}

.MuiInput-underline:hover:not(.MuiInput-disabled-23):not(.MuiInput-focused-22):not(.MuiInput-error-25):before {
    border-bottom: 2px solid rgba(39, 183, 255, 1) !important;
}

.password-form label.Mui-focused {
    color: rgba(39, 183, 255, 1) !important;
}

.blut-checkbox {
    margin: 5px 5px 5px 5px;
}

.password {
    margin: 5px 5px 5px 5px;
}

.accept-checkbox {
    cursor: default;
    font-size: 14px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
}

/*
Password
*/
.text-field-entry.MuiFormControl-root {
    margin-top: 10px;
}

.password-form {
    width: 100%;
}

.blut-password-container .MuiFormControlLabel-root {
    cursor: default;
    margin: 30px 0 0;
}

.accept-consent {
    margin: 0 10px 20px 10px;
}

.role-link {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0 5px;
}

.point {
    margin-left: -5px;
}

.app-id {
    margin: 0 10px 10px;
    word-break: break-all;
}

.qrcode-card canvas {
    display: flex;
    margin: 0 auto 10px;
}

.study-btn {
    width: 100%;
    display: flex;
    justify-content: center;
}

.identification-code {
    gap: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.code {
    font-weight: bold;
    margin-bottom: 10px;
}

/* QuestionnaireView CSS */

.page-container.qtn-container {
    overflow: inherit;
    height: min-content;
    letter-spacing: 0.15px;
    min-height: 100%;
}

@media only screen and (max-width: 1024px) and (min-device-width: 768px) {
    .page-content,
    .page-container {
        min-width: auto !important;
    }
}

@media only screen and (max-width: 768px) {
    .page-content,
    .page-container {
        min-width: auto;
        padding: 0 5px 0 5px;
    }
}

@media only screen and (max-width: 220px) {
    .page-content,
    .page-container {
        margin: 0;
    }
    .qtn-question-container {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }
}

.page-content-box.qtn-content-box {
    overflow-x: inherit;
    overflow-y: inherit;
    border: 0;
    border-radius: inherit;
    box-shadow: inherit;
    transition: none;
    -webkit-transition: none;
    background-color: inherit;
    max-width: inherit;
    margin: 0;
    padding: 0;
}
.qtn-container .qtn-question-container.MuiList-root {
    color: rgba(0, 0, 0, 0.87);
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    word-break: break-word;
    border: 1px solid #dadce0;
    text-align: left;
    max-width: 800px;
    border-radius: 8px;
    margin: 0 auto;
    padding: 10px 25px;
    max-height: inherit;
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 2vh;
}
.menu-button-header .headline {
    display: block !important;
    max-width: 850px;
    word-wrap: anywhere;
    margin: 2vh auto;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.87);
}
.qtn-question-container .qtn-choice-answer {
    width: 100%;
}
.qtn-progress-indicator {
    display: none;
}
.MuiRadio-colorPrimary {
    color: #4473c5;
}
