.notification-info .MuiPaper-elevation6,
.notification-success .MuiPaper-elevation6,
.notification-error .MuiPaper-elevation6 {
    box-shadow: none;
}

.notification-error.MuiSnackbar-anchorOriginTopCenter,
.notification-info.MuiSnackbar-anchorOriginTopCenter,
.notification-success.MuiSnackbar-anchorOriginTopCenter {
    top: 3px;
}

.notification-error .MuiSnackbarContent-message,
.notification-info .MuiSnackbarContent-message,
.notification-success .MuiSnackbarContent-message {
    padding: 0 15px 26px;
}

.notification-error .MuiSnackbarContent-root,
.notification-info .MuiSnackbarContent-root,
.notification-success .MuiSnackbarContent-root {
    display: flex;
    flex-direction: column-reverse;
    word-break: break-word;
    max-width: 100%;
}
