.cycle-bar,
.cycle-bar-container {
    min-width: 300px;
    width: 95vw;
    height: 40px;
}

.cycle-bar-container {
    max-width: 100%;
}
