.cycle-container {
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 1);
    height: calc(100% - 110px);
}

.cycle-datepicker {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.cycle-datepicker button {
    padding: 0;
}

.cycle-datepicker button:hover {
    background: none;
}

.cycle-datepicker-left {
    flex: 1;
    display: flex;
    padding-right: 21px;
}

.cycle-datepicker-left button {
    margin-left: auto;
    order: 2;
}

.cycle-datepicker-right {
    flex: 1;
    padding-left: 21px;
}

.cycle-datepicker-right svg,
.cycle-datepicker-left svg {
    color: rgba(39, 183, 255, 1);
    height: 31px;
    width: 31px;
}

.cycle-button-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.cycle-content {
    background-color: #f0f3f5;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cycle-content .cycle-tracker-container {
    height: calc(100% - 40px);
}
