.blut-footer {
    margin: 7px 0 30px 0;
    text-align: center;
}

.links {
    display: flex;
    flex-direction: row;
    gap: 5px;
    font-size: 12px;
}

.footer-link {
    color: rgba(39, 183, 255, 1);
    text-decoration: underline;
    cursor: pointer;
}

.slash-separator {
    cursor: default;
}
