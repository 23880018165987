.insights-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.insights-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.insights-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    justify-content: space-around;
    min-width: 300px;
    max-width: 600px;
    width: 100%;
}

.insights-header-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.insights-header-item-svg {
    width: 40px;
    height: 40px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.insights-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    flex: 1;
}

.insights-cycle {
    display: flex;
    flex-direction: column;
}

.insights-cycle-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 300px;
    width: 100%;
}

.MuiList-root {
    width: 100%;
}

.insights-bold {
    font-weight: bold;
}
.insights-cycle-bar {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
}
