.instance-settings-list-container {
    display: flex;
    flex-direction: column;
}

.custom-card.instanceCard {
    margin-left: 10px;
    margin-right: 10px;
    width: auto !important;
}

.instance-setting-item-margin {
    margin-bottom: 8px;
    word-break: break-all;
}

.custom-card.connectionsCard {
    box-shadow: none;
    border-radius: 0px !important;
    width: auto !important;
}

.connections {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.connections-info {
    flex: 1;
}

.connections-info .instance-setting-item-margin {
    margin-bottom: 0;
}

.view-title {
    margin-left: 10px !important;
}

.custom-card {
    padding-left: 5px;
    padding-right: 5px;
}

.delete-instance-button {
    width: 100%;
    margin-bottom: 10px;
}

@media (max-device-width: 767px) {
    .instance-settings-list-container {
        margin: 0;
        overflow: auto;
    }
}
