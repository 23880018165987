.custom-modal .MuiPaper-root {
    width: 100%;
    max-width: 600px;
}

.custom-modal .MuiDialog-container {
    display: flex;
    align-items: start;
}

.custom-dialog-close-button.MuiButtonBase-root {
    position: absolute;
    right: 0;
    padding: 4px;
    margin: 5px 10px 0;
    z-index: 20;
}

.custom-dialog-close {
    width: 100%;
    height: 35px;
    display: contents;
}

.center .MuiPaper-root {
    margin: auto 10px;
}

