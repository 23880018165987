.instance-settings {
    overflow-wrap: break-word;
}
.instance-setting-item {
    line-height: 0;
}

.instance-setting-item-margin {
    margin-bottom: 12px;
}

.delete-instance-button {
    width: 100%;
}

.instance-settings-wrapper {
    display: flex;
    flex-direction: column;
}

.instance-settings-wrapper .MuiTypography-root {
    word-break: break-word;
}
