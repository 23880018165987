.app-bar-container.MuiAppBar-positionFixed {
    top: auto;
    bottom: 0;
    background: #FAD1D6;
    color: black;
}

.app-bar-container .MuiToolbar-root {
    height: 50px;
    min-height: 50px;
}

.app-bar-grow {
    flex: 1;
}

.app-bar-title {
    margin-left: 15px;
}
