.modal-container {
    margin: 24px;
}

.modal-title {
    font-weight: bold;
}

.modal-actions-container {
    margin-top: 32px;
    display: flex;
}

.box-container {
    position: absolute;
    top: 20vh;
    border-radius: 4px;
    padding:20px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    background: white;
}
